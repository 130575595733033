/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Servis çalışanı kaydedilmiştir.')
    },
    cached () {
      console.log('İçerik çevrimdışı kullanım için önbelleğe alınmıştır.')
    },
    updatefound () {
      console.log('Yeni içerik indiriliyor.')
    },
    updated () {
      console.log('Yeni içerik mevcuttur; lütfen yenileyin.')
    },
    offline () {
      console.log('İnternet bağlantısı bulunamadı.')
    },
    error (error) {
      console.error('Hizmet çalışanı kaydı sırasında hata oluştu:', error)
    }
  })
}
