<script lang="js">
import {ref} from "vue";
import Multiselect from "@vueform/multiselect";
import Search from "@/components/Search.vue";
import Eye from "@/components/Eye.vue";
import axios from "axios";
export default {
  name: 'HomeView',
  components: {Multiselect, Search, Eye},

  setup() {
    const search = ref('');
    const respData = ref();
    const kuranHattiMi = ref(false);
    const manadaAraMi = ref(false);
    let tooltipIndex = null;
    const loading = ref(false);
    const isNotFOund = ref(false);

    const options = ref([
      "İsam",
      "Kamus",
      "Kamus-u Türki",
      "Vankulu",
      "Kubbe Altı",
      "Luggat",
      "Lugat-ı Remzi",
      "Hadis Terimleri",
      "İsim Yazılışları",
      "Osmanlıca Yazılışları",
      "arapça kelimeler",
      "el-isabe",
      "tabakat",
      "Kontrollu",
      "İhya Hadis Sözlüğü",
      "Diyanet Hadis Terimleri",
      "Dini Kavramlar Sözlüğü",
      "www.ebuzziya.com",
      "www.lugatinaci.com",
      "www.lehceiosmani.com",
      "www.resimlikamus.com",
      "www.turkcelugat.com",
      "www.turkcekamus.com",
      "www.kamusiosmani.net",
      "www.osmanlilugati.com",
      "www.ehlisunnetbuyukleri.com",
      "ARAPÇA ISTILAHLARI",
      "Fetâvâ-i Hindiyye",
      "TEFSİR TERİMLERİ SÖZLÜĞÜ",
      "TERİMLER VE KAVRAMLAR SÖZLÜĞÜ",
      "BELAGAT VE EDEBİ SANATLAR",
      "Fıkıh ve Hukuk Lugatı",
      "Arapça Türkçe Sözlük (tevakku)",
      "Aslı ve Günümüz Türkçeyle Mecelle",
      "Mecelle Istılahları",
      "Mantık Terimleri Sözlüğü",
      "Arapça Exe",
      "Arapça Farsça Exe",
      "Farisiler Exe",
      "Kuranda Geçen Exe",
      "Osmanlıca Lugat Exe",
      "Grup: Hadis Terimleri",
    ]);
    const optionsValue = ref([]);

    const filter = ref("-");

    const onInput = () => {
      if(optionsValue.value.length != 0){
        document.getElementsByClassName('multiselect-multiple-label')[0].innerText  = `${optionsValue.value.length} Tane Seçildi`
      }
      if (optionsValue.value.length != 0  && options.value.length === optionsValue.value.length)
        document.getElementsByClassName('multiselect-no-results')[0].innerText = 'Tümü seçildi'


    }

    const searchClick = async () => {
      filter.value = "-";
      try {
        if (optionsValue.value.length !== 0) {
          filter.value = optionsValue.value.join(',');
        }
        if (search.value.trim().length === 0) {
          return;
        }

        const url = `https://lugat.osmanlica.online/?kelime=${search.value}&kaynak=browser&sadecehattikuran=${kuranHattiMi.value}&filitre=${filter.value}&manadaara=${manadaAraMi.value}&json=True`
        loading.value = true;
        const response = await axios.get( url);
        console.log(response.data)
        respData.value = response.data
        loading.value = false;

        if (respData.value.length === 0) {
          isNotFOund.value = true;
        } else {
          isNotFOund.value = false;
        }

      } catch (error) {
        console.error(error);
      }
    };

    const kaynakAra = (kaynak) => {
      respData.value = respData.value.filter((item) => item.Kaynak === kaynak);
    };

    const kaynakGizle = (kaynak) => {
      respData.value = respData.value.filter((item) => item.Kaynak !== kaynak);
    };

    const tooltipStatus = ref(false);

    const showTooltip = (index) => {
      tooltipIndex = index;
    };

    const hideTooltip = () => {
      tooltipIndex = null;
    };

    return {
      search,
      options,
      optionsValue,
      kuranHattiMi,
      manadaAraMi,
      respData,
      tooltipStatus,
      tooltipIndex,
      loading,
      isNotFOund,

      searchClick,
      kaynakAra,
      kaynakGizle,
      showTooltip,
      hideTooltip,
      onInput,
    }
  }

}
</script>

<template>
<div>
  <div class="container justify-content-center mt-5 width">
    <div v-if="loading" class="d-flex justify-content-center mb-4"  >
      <div class="spinner-border" ></div>
      <span class="ms-2">YÜKLENİYOR...</span>
    </div>

    <div class="d-flex justify-content-center justify-content-sm-between mb-3">
      <div class="w-100 w-sm-75 me-2 me-sm-4 d-flex form-control shadow-sm" :aria-disabled="loading">
        <input
            class="border-0 form-control shadow-none"
            ref="searchInput"
            placeholder="Arama"
            v-model="search"
            @keyup.enter="searchClick"
        />
        <i
            v-if="search.length>0"
            class="my-auto bi bi-x-circle-fill"
            @click="()=>{
                        search='';
                        $refs.searchInput.focus();
                    }"
        > </i>
      </div>
      <div class="ms-sm-4 mt-1">
        <button
            class="btn btn-outline text-nowrap search"
            style="height: 38px; min-width: 100px;"
            @click="searchClick">
          <span class="d-none d-sm-inline">Kelimeyi </span>Ara
        </button>
      </div>
    </div>

    <div class="d-flex flex-wrap justify-content-start border-black ">
      <div class="d-flex border rounded-3 me-3 width  mb-3 checkbox-container shadow-sm" style="max-width: 350px">
        <input
               type="checkbox"
               class="form-check-input m-2"
               id="kuran"
               v-model="kuranHattiMi"
               @click="searchClick"
               :disabled="loading"
        >
        <label class="form-check-label m-2"  for="kuran">
          Sadece Hatt-ı Kuran İçerenleri Göster
        </label>
      </div>
      <div class="d-flex border rounded-3 me-3 width mb-3 checkbox-container shadow-sm" style="max-width: 200px">
        <input
            type="checkbox"
            class="form-check-input m-2"
            id="mana"
            v-model="manadaAraMi"
            @click="searchClick"
            :disabled="loading"
        >
        <label class="form-check-label m-2" for="mana">Manada Ara</label>
      </div>

      <div class="width mb-3" style="max-width: 250px;">
        <Multiselect
            v-model="optionsValue"
            :options="options"
            class="multiselect-blue shadow-sm"
            mode="multiple"
            :searchable="true"
            :close-on-select="false"
            @click ="onInput"
            :disabled="loading"

        />
      </div>
    </div>

  </div>

  <div v-for="(item, index) in respData" :key="index">
    <div class="container mt-5 width justify-content-center">
      <div class="card shadow-sm">
        <div class="card-header bg-white">
          <div class=" d-flex justify-content-between">
            <div class="text-start">
              <p class="fw-bold pt-2" style="font-size: 0.9em">{{ item.Kelime }}</p>
            </div>

            <div class="d-flex align-content-center ps-lg-3 border-4">
              <div class="me-2 tooltipp ">
                <button
                    type="button"
                    class="btn btn-outline  border-0"
                    @click="kaynakAra(item.Kaynak)"
                >
                  <div>
                    <Search/>
                  </div>
                </button>
                <span class="tooltiptext top">{{ `Sadece ${item.Kaynak} kaynağından gelen bilgileri göster` }}</span>
              </div>

              <div class="tooltipp ">
                <button
                    type="button"
                    style="border:none"

                    class="btn btn-outline border-0 "
                    @click="kaynakGizle(item.Kaynak)"
                >
                  <Eye/>
                </button>
                <span class="tooltiptext top">{{ `${item.Kaynak} kaynağından gelen bilgileri kapat` }}</span>
              </div>

              <div class="kaynak form-control justify-content-end mt-2 mt-md-0">
                <span class="fw-bold" style="color: #2E61BD; font-size: 0.9em">{{ item.Kaynak }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="card-text" style="text-align: justify; text-justify: inter-word;" v-html="item.Mana"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isNotFOund"  class="container mt-5 width justify-content-center">
    <div class="card shadow-sm">
      <div class="card-body my-1">
        Bulunamadı ...
      </div>
    </div>
  </div>

</div>

</template>

<style scoped>

.search {
  background-color: #2E61BD;
  color: white;
}

.kaynak {
  border: #2E61BD;
  border-style: solid;
  border-radius: 10px;
  justify-content: center;
  background-color: #E8EEF8;
}


.clearsearch {
  color: #fff;
  border-radius: 1000px;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 2px 5px 3px;
  right: 43px;
  top: 7px;
  height: 18px;
  opacity: 0.45;
  cursor: pointer;
  display: inline;
}

.width {
  width: 100%;
}

@media (min-width: 768px) {
  .width {
    width: 75%;
  }
}

.btn-outline {
  --bs-btn-color: #2E61BD;
  --bs-btn-border-color: #2E61BD;
  --bs-btn-hover-color: #2E61BD;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #2E61BD;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2E61BD;
  --bs-btn-active-border-color: #2E61BD;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2E61BD;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2E61BD;
  --bs-gradient: none;
}



* {
  box-sizing: border-box;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container label {
  cursor: pointer;
  display: flex;
}

.checkbox-container input[type='checkbox'] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.checkbox-container label::before {
  content: '';
  width: 1.5em;
  height: 1.5em;
  border-radius: .15em;
  margin-right: .5em;
  border: .05em solid #2E61BD;
}

.checkbox-container label:hover::before,
.checkbox-container input[type='checkbox']:hover + label::before {
  background-color: #E8EEF8;
}


.checkbox-container input[type='checkbox']:disabled + label,
.checkbox-container input[type='checkbox']:disabled {
  color: #E8EEF8;
  cursor: default;
}

.checkbox-container input[type='checkbox']:checked + label::before {
  content: '\002714';
  background-color: #E8EEF8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2E61BD;
}

.checkbox-container input[type='checkbox']:disabled + label::before {
  background-color: #ccc;
  border-color: #999;
}

.tooltipp {
  display:inline-block;
  position:relative;
  text-align:left;
}

.tooltipp h3 {margin:12px 0;}


.tooltipp {
  display:inline-block;
  position:relative;
  text-align:left;
}

.tooltipp .top {
  top:-20px;
  left:50%;
  transform:translate(-50%, -100%);
  color:white;
  padding: 10px 20px;
  background-color:#0275d8;
  font-weight:normal;
  font-size:13px;
  text-align: center;
  min-width: 200px;
  border-radius:8px;
  position:absolute;
  z-index:99999999;
  box-sizing:border-box;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
  display:none;
}

.tooltipp:hover .top {
  display:block;
}

.tooltipp .top i {
  position:absolute;
  top:100%;
  left:50%;
  margin-left:-12px;
  width:24px;
  height:12px;
  overflow:hidden;
}

.tooltipp .top i::after {
  content:'';
  position:absolute;
  width:12px;
  height:12px;
  left:50%;
  transform:translate(-50%,-50%) rotate(45deg);
  background-color:black;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
}




</style>

<style src="@vueform/multiselect/themes/default.css"></style>
