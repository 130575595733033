<template>
  <div class="card position-absolute  z-3 " style="width: 18rem;" v-if="shown">
    <div class="card-body">
      <p class="card-text">
        Uygulamayı ana ekranınıza ekleyin.
      </p>
      <div class="mt-2">
          <button class="btn btn-success me-2" @click="installPWA">
            Ekle
          </button>
          <button class="btn btn-danger" @click="dismissPrompt">
            İstemiyorum
          </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    shown: false,
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },

  methods: {
    dismissPrompt() {
      this.shown = false
    },

    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        // if (choice.outcome === 'accepted') {
        //   // Do something additional if the user chose to install
        // } else {
        //   // Do something additional if the user declined
        // }
      })
    },
  }
}
</script>