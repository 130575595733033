<script setup>

</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1_518" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="currentColor"/>
    </mask>
    <g mask="url(#mask0_1_518)">
      <path d="M11.25 13.5C11.8833 13.5 12.4167 13.2833 12.85 12.85C13.2833 12.4167 13.5 11.8833 13.5 11.25C13.5 10.6167 13.2833 10.0833 12.85 9.65C12.4167 9.21667 11.8833 9 11.25 9C10.6167 9 10.0833 9.21667 9.65 9.65C9.21667 10.0833 9 10.6167 9 11.25C9 11.8833 9.21667 12.4167 9.65 12.85C10.0833 13.2833 10.6167 13.5 11.25 13.5ZM16.075 17.5L13.45 14.875C13.1167 15.0917 12.7625 15.25 12.3875 15.35C12.0125 15.45 11.6333 15.5 11.25 15.5C10.0667 15.5 9.0625 15.0875 8.2375 14.2625C7.4125 13.4375 7 12.4333 7 11.25C7 10.0667 7.4125 9.0625 8.2375 8.2375C9.0625 7.4125 10.0667 7 11.25 7C12.4333 7 13.4375 7.4125 14.2625 8.2375C15.0875 9.0625 15.5 10.0667 15.5 11.25C15.5 11.6333 15.4458 12.0125 15.3375 12.3875C15.2292 12.7625 15.0667 13.1167 14.85 13.45L17.5 16.1L16.075 17.5ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V15H5V19H9V21H5ZM15 21V19H19V15H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H15ZM3 9V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9V5H5V9H3ZM19 9V5H15V3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V9H19Z" fill="currentColor"/>
    </g>
  </svg>

</template>

<style scoped>

</style>